//store/modules/home.js
const state = {
  menuItems: null,
  selectedMenuItem: null, // New state to track the selected menu item
  isLoading: false, //status of the loading page
  loadingCount : 0
};

const mutations = {
  SET_MENU_ITEMS(state, menuItems) {
    state.menuItems = menuItems;
  },
  SET_SELECTED_MENU_ITEM(state, selectedMenuItem) {
    state.selectedMenuItem = selectedMenuItem;
  },
  START_LOADING(state){
    state.isLoading = true
    state.loadingCount+=1
  },
  STOP_LOADING(state){
    state.isLoading = false
    state.loadingCount-=1
  }
};

const actions = {
  setMenuItems({ commit }, menuItems) {
    commit('SET_MENU_ITEMS', menuItems);
  },
  setSelectedMenuItem({ commit }, selectedMenuItem) {
    commit('SET_SELECTED_MENU_ITEM', selectedMenuItem);
  },
};

const getters = {
  isLoading(){
    return state.isLoading
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
