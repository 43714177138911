<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Services</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="serviceTable" class="table mt-3">
          <thead>
          <tr>
            <th>Service Id</th>
            <th>Service Name</th>
            <th>Duration</th>
            <th>Service Image</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="service in services" :key="service.serviceId">
            <td>{{ service.serviceId }}</td>
            <td>{{ service.serviceName }}</td>
            <td>{{ service.duration }}</td>
            <td><img :src="service.serviceImage" alt="" width="100" height="70"></td>
            <td>
              <p v-if="service.active" class="ongoing" style="text-align:center">{{ service.active }}</p>
              <p v-else class="progress1" style="text-align:center">{{ service.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(service,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Store</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Service Name </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedServiceName" class="form-control" placeholder="Service Name">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Duration</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedDuration" class="form-control" placeholder="Duration">
                  </div>
                </div>
                <div class="row mb-1"  v-if="updatedServiceImage">
                  <label class="col-sm-2 col-form-label">Service Image </label>
                  <div class="col-sm-3">
                    <img :src="updatedServiceImage" alt="" width="100" height="70">
                  </div>
                  <div class="col-sm-7">
                    <input type="file" @change="handleFileChange" class="form-control">
                  </div>
                </div>
                <div class="row mb-1"  v-else>
                  <label class="col-sm-4 col-form-label">Service Image </label>
                  <div class="col-sm-8">
                    <input type="file" @change="handleFileChange" class="form-control">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="col-sm-8">
                    <select v-if="editFlag" v-model="updatedActive" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="update">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import serviceAPI from '@/api/service/service';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      services: [],
      updatedService: "",
      editingService: null,
      editFlag: false,
      updatedServiceName: null,
      updatedDuration: null,
      updatedServiceImage: null,
      updatedActive: null,
      file: '',
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openEditModal(service, flag) {
      this.editingService = service;
      this.editFlag = flag;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedServiceName = this.editingService.serviceName;
        this.updatedDuration = this.editingService.duration;
        this.updatedServiceImage = this.editingService.serviceImage;
        this.updatedActive = this.editingService.active;
      } else {
        this.updatedServiceName = "";
        this.updatedDuration = "";
        this.updatedServiceImage = "";
        this.updatedActive = true;
      }

      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.updatedServiceName = "";
      this.updatedDuration = "";
      this.updatedServiceImage = "";
      this.updatedActive = "";
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchServices() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await serviceAPI.getServices();
        this.services = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error(error,'Error fetching Services:');
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async update() {
      let formData = new FormData();
      formData.append('serviceName', this.updatedServiceName);
      formData.append('duration', this.updatedDuration);
      if (this.file) {
        if (this.file.size > 100 * 1024 * 1024) {
          alert('File size should be less than 100MB');
        }
        if (!['image/jpeg', 'image/png', 'application/pdf'].includes(this.file.type)) {
          alert('File type not supported. Supported types: JPEG, PNG, PDF');
        }
        formData.append('serviceImage', this.file);
      }
      formData.append('active', this.updatedActive);
      try {
        this.$store.commit('home/START_LOADING')
        let response = null;
        if (this.editFlag) {
          response = await serviceAPI.updateServices(this.editingService.serviceId, formData);
        } else {
          response = await serviceAPI.addServices(formData);
        }

        if (response.data.success) {
          alert(response.data.message);
          await this.fetchServices();
          this.closeEditModal();
        }

      } catch (error) {
        console.error(error, 'Error while updating/adding services:');
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    getExportTitle() {
      return 'Services';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.serviceTable)) {
        $(this.$refs.serviceTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2, 3, 4];

      this.$nextTick(() => {
        $(this.$refs.serviceTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchServices();
  },
};
</script>
