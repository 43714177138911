import axios from 'axios';
import { BASE_URL, getAuthHeader } from './apiUtils';

const userAPI = {


  changePassword(data){
    return axios.post(`${BASE_URL}/authenticate/changePassword`, data, { headers: getAuthHeader() });
  },

  getAdmins() {
    return axios.get(`${BASE_URL}/users/get-admins`, { headers: getAuthHeader() });
  },

  resetPassword(data) {
    return axios.post(`${BASE_URL}/authenticate/resetPassword`, data, { headers: getAuthHeader() });
  },

  getClients() {
    return axios.get(`${BASE_URL}/users/get-clients`, { headers: getAuthHeader() });
  },

  getVendors() {
    return axios.get(`${BASE_URL}/users/get-vendors`, { headers: getAuthHeader() });
  },
  addVendor(data){
    const headers = {
      ...getAuthHeader(),
      'Content-Type': 'multipart/form-data'
    };
    return axios.post(`${BASE_URL}/users/add-vendor`, data, { headers});
  },
  addAdmin(data) {
    return axios.post(`${BASE_URL}/users/add-admin`, data, { headers: getAuthHeader() });
  },
  updateAdmin(updatedData,id){
    return axios.put(`${BASE_URL}/users/update-admin/${id}`,updatedData,{headers: getAuthHeader()});
  },
  updateVendor(updatedData,id){
    const headers = {
      ...getAuthHeader(),
      'Content-Type': 'multipart/form-data'
    };
    return axios.put(`${BASE_URL}/users/update-vendor/${id}`,updatedData,{headers});
  },
  patchAdmin(id) {
    return axios.put(`${BASE_URL}/users/block-or-unblock-admin/`+id,"",{ headers: getAuthHeader() });
  },

  patchClients(id) {
    return axios.put(`${BASE_URL}/users/block-or-unblock-client/`+id,"",{ headers: getAuthHeader() });
  },

  patchVendors(id) {
    return axios.put(`${BASE_URL}/users/block-or-unblock-vendor/`+id,"",{ headers: getAuthHeader() });
  },

};

export default userAPI;
