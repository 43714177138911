<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Stores</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="countryDropdown" style="margin-top:5px;">Select Country</label>
        <select v-model="selectedCountry" @change="fetchStates(selectedCountry)" id="countryDropdown"
                class="form-select"
                :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') "
        >
          <option v-if="configData.masterRolesId === '1'" value="0">ALL</option>
          <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
            {{ country.countryName }}
          </option>
        </select>
      </div>

      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="stateDropdown" style="margin-top:5px;">Select State</label>
        <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState)" id="stateDropdown"
                class="form-select"
                :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') "
        >
          <option v-if="configData.masterRolesId === '1'" value="0">ALL</option>
          <option v-for="state in states" :key="state.stateId" :value="state.stateId">{{ state.stateName }}</option>
        </select>
      </div>


      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="cityDropdown" style="margin-top:5px;">Select City</label>
        <select :disabled="(configData.masterRolesId === '3') " v-model="selectedCity" id="cityDropdown"
                class="form-select" @change="fetchOwners(selectedCountry,selectedState,selectedCity)">
          <option v-if="(configData.masterRolesId === '1' || configData.masterRolesId === '4')" value="0">ALL</option>
          <option v-for="city in cities" :key="city.cityId" :value="city.cityId">{{ city.cityName }}
          </option>
        </select>
      </div>

      <div v-if="configData.masterRolesId === '1' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label style="margin-top:5px;">Select Owner</label>
        <select v-model="selectedOwner" class="form-select">
          <option v-if="configData.masterRolesId === '1'" value="0">ALL</option>
          <option v-for="owner in owners" :key="owner.userId" :value="owner.userId">{{ owner.fullName }}</option>
        </select>
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5" style="margin-top:25px;">
        <button v-if="(configData.masterRolesId === '1' || configData.masterRolesId === '4') " @click="fetchStores"
                class="btn btn-success">Submit
        </button>
      </div>
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button v-if="(configData.masterRolesId === '1' || configData.masterRolesId === '4') "
                @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="storesTable" class="table mt-3">
          <thead>
          <tr>
            <th>Store Name</th>
            <th>Mobile & <br/> Email</th>
            <th>Owner Name</th>
            <th>Address</th>
            <th>Pincode</th>
            <th>Latitude, Longitude</th>
            <th>Active</th>
            <th v-if="admin">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="store in stores" :key="store.storeId">
            <td>{{ store.storeName }}</td>
            <td>{{ store.mobile }} <br/> {{ store.email }}</td>
            <td>{{ store.ownedByName }}</td>
            <td>{{ store.address }}</td>
            <td>{{ store.pincode }}</td>
            <td>{{ store.latitude }},<br/>{{ store.longitude }}</td>
            <td>
              <p v-if="store.active" class="ongoing" style="text-align:center">{{ store.active }}</p>
              <p v-else class="progress1" style="text-align:center">false</p>
            </td>
            <td v-if="admin">
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(store,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Store</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div v-if="configData.masterRolesId !== '2' " class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Country</label>
                  <div class="col-sm-8">
                    <select v-model="updatedStore.countryId" @change="fetchStates(updatedStore.countryId)"
                            class="form-select"
                            :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') ">
                      <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="configData.masterRolesId !== '2' " class="row mb-1">
                  <label class="col-sm-4 col-form-label"> State</label>
                  <div class="col-sm-8">
                    <select v-model="updatedStore.stateId"
                            @change="fetchCities(updatedStore.countryId,updatedStore.stateId)"
                            class="form-select"
                            :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') ">
                      <option v-for="state in states" :key="state.stateId" :value="state.stateId">{{
                          state.stateName
                        }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="configData.masterRolesId !== '2' " class="row mb-1">
                  <label class="col-sm-4 col-form-label"> City</label>
                  <div class="col-sm-8">
                    <select :disabled="(configData.masterRolesId === '3') " v-model="updatedStore.cityId"
                            class="form-select"
                            @change="fetchAreas(updatedStore.countryId,updatedStore.stateId,updatedStore.cityId)">
                      <option v-for="city in cities" :key="city.cityId" :value="city.cityId">{{ city.cityName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="configData.masterRolesId !== '2' " class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Area</label>
                  <div class="col-sm-8">
                    <select :disabled="(configData.masterRolesId === '3') " v-model="updatedStore.areaId"
                            class="form-select"
                            @change="fetchOwners(updatedStore.countryId,updatedStore.stateId,updatedStore.cityId)">
                      <option v-for="area in areas" :key="area.areaId" :value="area.areaId">{{ area.areaName }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Store Name </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStore.storeName" class="form-control"
                           placeholder="Store Name">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Address </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStore.address" class="form-control" placeholder="Address">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Pincode </label>
                  <div class="col-sm-8">
                    <input type="number" v-model="updatedStore.pincode" class="form-control"
                           placeholder="Pincode">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Latitude </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStore.latitude" class="form-control"
                           placeholder="Latitude">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Longitude </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStore.longitude" class="form-control"
                           placeholder="longitude">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Store Mobile </label>
                  <div class="col-sm-8">
                    <input type="number" v-model="updatedStore.mobile" :maxlength="max"  class="form-control" maxlength="10"
                           placeholder="Mobilenumber">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Store Email </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStore.email" class="form-control" placeholder="Email">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Owned By </label>
                  <div class="col-sm-8">
                    <select v-model="updatedStore.ownedBy" class="form-select">
                      <option v-for="owner in allOwners" :key="owner.userId" :value="owner.userId">
                        {{ owner.fullName }} - {{ owner.primaryMobileNumber }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="col-sm-8">
                    <select v-if="editFlag" v-model="updatedStore.active" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateStore">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import stateAPI from '@/api/location/state';
import countryAPI from '@/api/location/country';
import cityAPI from '@/api/location/cities.js';
import storeAPI from '@/api/store/store.js';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      selectedOwner: 0,
      countries: [],
      states: [],
      cities: [],
      stores: [],
      owners: [],
      allOwners: [],
      areas: [],
      updatedStore: "",
      editingStore: null,
      editFlag: "",
      configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
      admin: false,
    };
  },
  methods: {
    openEditModal(store, flag) {
      this.editingStore = store;
      this.editFlag = flag;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedStore = store;
        this.fetchCities(this.updatedStore.countryId, this.updatedStore.stateId);
        this.fetchAreas(this.updatedStore.countryId, this.updatedStore.stateId, this.updatedStore.cityId);
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      } else {

        this.updatedStore = {
          storeName: "",
          storeId: null,
          cityId: this.selectedCity,
          stateId: this.selectedState,
          countryId: this.selectedCountry,
          address: "",
          pincode: "",
          latitude: "",
          max: 10,
          longitude: "",
          mobile: "",
          email: "",
          ownedBy: this.selectedOwner,
          active: true
        };
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      }
    },
    closeEditModal() {
      this.updatedStore = "";
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchCountries() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await countryAPI.getCountries();
        this.countries = response.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchOwners(country, state, city) {
      try {
        this.$store.commit('home/START_LOADING')
        let path = '';
        if (country !== null) {
          path += `country_id=${country}`;
        }
        if (state !== null) {
          path += `&state_id=${state}`;
        }
        if (city !== null) {
          path += `&city_id=${city}`;
        }

        const response = await storeAPI.fetchVendors(path);
        this.owners = response.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchAllOwners() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await storeAPI.fetchUsers();
        this.allOwners = response.data;
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchStates(country) {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await stateAPI.getStates(country);
        this.states = response.data;
        await this.fetchOwners(country, null, null);
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchCities(country, state) {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data;
        await this.fetchOwners(country, state, null);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchAreas(country, state, city) {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await cityAPI.getArea(country, state, city);
        this.areas = response.data;
        await this.fetchOwners(country, state, city);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async updateStore() {
      const updateStore = {
        storeName: this.updatedStore.storeName,
        countryId: this.updatedStore.countryId,
        stateId: this.updatedStore.stateId,
        cityId: this.updatedStore.cityId,
        areaId: this.updatedStore.areaId,
        address: this.updatedStore.address,
        pincode: this.updatedStore.pincode,
        latitude: this.updatedStore.latitude,
        longitude: this.updatedStore.longitude,
        mobile: this.updatedStore.mobile,
        email: this.updatedStore.email,
        ownedBy: this.updatedStore.ownedBy,
        active: this.updatedStore.active,
      };
      try {
        this.$store.commit('home/START_LOADING')
        let response = null;
        if (this.editFlag) {
          response = await storeAPI.updateStores(this.updatedStore.storeId, updateStore);
        } else {
          response = await storeAPI.addStores(updateStore);
        }
        alert(response.data.message);
        if (response.data.success) {
          await this.fetchStores();
          this.closeEditModal();
        }
      } catch (error) {
        alert(error.response.data.message);
        console.error('Error fetching cities:', error);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchStores() {
      try {
        this.$store.commit('home/START_LOADING')
        let path = "";
        if (this.selectedCountry !== null) {
          path += `country_id=${this.selectedCountry}`;
        }
        if (this.selectedState !== null) {
          path += `&state_id=${this.selectedState}`;
        }
        if (this.selectedCity !== null) {
          path += `&city_id=${this.selectedCity}`;
        }

        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        } else {
          if (this.selectedOwner !== null) {
            path += `&owner_id=${this.selectedOwner}`;
          }
        }
        const response = await storeAPI.getStores(path, false);
        this.stores = response.data;
        this.initializeDataTable();
      } catch (error) {
        alert(error.response.data.message);
        console.error('Error fetching cities:', error);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    getExportTitle() {
      const selectedCountry = this.countries.find(country => country.countryId === this.selectedCountry);
      if (selectedCountry) {
        return `${selectedCountry.countryName.replace(/ /g, '_')}_states`;
      } else {
        return 'Exported_states';
      }
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.storesTable)) {
        $(this.$refs.storesTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2, 3, 4, 5, 6, 7];

      this.$nextTick(() => {
        $(this.$refs.storesTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    if (this.configData.masterRolesId === '2') {
      this.fetchStores();
    } else if (this.configData.masterRolesId === '4') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.fetchStores();
      this.fetchOwners(this.configData.countryId, this.configData.stateId, null);

    } else if (this.configData.masterRolesId === '3') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.selectedCity = this.configData.cityId;
      this.fetchStores();
      this.fetchOwners(this.configData.countryId, this.configData.stateId, this.configData.cityId);
    } else {
      this.fetchAllOwners();
      this.fetchCountries();
      this.selectedCountry = '1';
      this.fetchStates(1);
    }
    if (this.configData) {
      this.admin = this.configData.admin;
    }
  },
};
</script>
