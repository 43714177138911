<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Taxes List</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">

        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <button @click="openModal(null)" class="btn btn-success" style="position:absolute;right:30px;">
            Add
          </button>
          <!-- taxes Table using DataTables -->
          <table ref="taxesDataTable" class="table mt-3">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Percentage</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tax  in taxes" :key="tax.taxId">
                <td>{{ tax.taxId }}</td>
                <td>{{ tax.taxName }}</td>
                <td>{{ tax.taxPercentage }}</td>
                <td>
                  <p v-if="tax.active" class="ongoing" style="text-align:center">{{ tax.active }}</p>
                  <p v-else class="progress1" style="text-align:center">{{ tax.active }}</p>
                </td>
                <td>
                  <div class="three-images d-flex">
                    <a href="#" @click="openModal(tax)">
                      <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1"  alt="">
                    </a>
                    <a href="#" @click="disableDetails(tax,false)" v-if="tax.active">
                    <img :src="require('@/assets/images/block.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                  <a href="#" @click="disableDetails(tax,true)" v-else>
                    <img :src="require('@/assets/images/unlocked.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> 
           
        </div>
      </div>
    </div>  
    <div id="openModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Details</h5>
            <button type="button" class="btn btn-danger" @click="closeModal">
              <span>&times;</span>
            </button>
          </div> 
          <div class="modal-body">
            <label>Name</label>
            <input type="text" v-model="name" class="form-control" placeholder="name">
            <label>Message</label>
            <input type="text" v-model="percentage" class="form-control"  placeholder="percentage 5">
          </div> 
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
            <button type="button" class="btn btn-primary" @click="buttonClick">{{ isEdit ? 'Update':'Add' }}</button>
          </div>
        </div>
      </div>
    </div>      
</template>

<script>

import taxesAPI from '@/api/other-configs/taxes.js'
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            taxes : [],
            selectedTax: {},
            name:'',
            percentage:'',
            isEdit:false
            
        }
    },
    methods: {
        async fetchTaxes(){
            try{
              this.$store.commit('home/START_LOADING')
                const response =  await taxesAPI.get();
                this.taxes = response.data 
                this.initializeDataTable();
            } catch (error) {
                console.error("Error fetching countries:",error);
            } finally{
              this.$store.commit('home/STOP_LOADING')
            }
        },
        initializeDataTable() {
            // Destroy existing DataTable
            if ($.fn.DataTable.isDataTable(this.$refs.taxesDataTable)) {
            $(this.$refs.taxesDataTable).DataTable().destroy();
            }
            // Initialize DataTable when the component is mounted or when states change
            this.$nextTick(() => {
                $(this.$refs.taxesDataTable).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                    {
                        extend: 'copy',
                        title: 'taxesList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'csv',
                        title: 'taxesList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'excel',
                        title: 'taxesList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'pdf',
                        title: 'taxesList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'print',
                        title: 'taxesList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    ],
                    // Add any DataTables options you need here
                });
            });
        },
        openModal(tax){
          if(tax){
            this.selectedTax=tax;
            this.name =tax.taxName;
            this.percentage=tax.taxPercentage
            this.isEdit=true
          }
          else{
            this.name=''
            this.percentage=''
            this.isEdit=false
          }
          const modal = document.getElementById('openModal');
          if(modal){
            modal.classList.add('show');
            modal.style.display = 'block';
          }
        },
        closeModal(){
          this.name=''
          this.percentage=''
          const modal = document.getElementById('openModal');
            if (modal) {
              modal.classList.remove('show');
              modal.classList.remove('hide');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
              const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
              if (modalBackdrop) {
                  modalBackdrop.parentNode.removeChild(modalBackdrop);
              }
          }
        },
        async addDetails(){
          try{
            this.$store.commit('home/START_LOADING')
            const data = {taxName:this.name,taxPercentage:this.percentage,active:true}
            const response =  await taxesAPI.post(data);
            
            this.isEdit=false
            this.closeModal()
            await this.fetchTaxes();
          }catch(error){
            console.error("Error while adding taxes",error)
          } finally {
            this.$store.commit('home/STOP_LOADING')
          }
        },
        async editDetails(){
          try{
            const data = {taxName:this.name,taxPercentage:this.percentage,active:true}
            const response = await taxesAPI.update(this.selectedTax.taxId,data);
            this.isEdit=false
            this.selectedTax={}
            this.closeModal()
            await this.fetchTaxes();
            
          } catch(error){
            console.error("Error while editing taxes",error)
          }
        },
        async disableDetails(tax, status){
          try{
            const data = {taxName:tax.taxName,taxPercentage:tax.taxPercentage,active:status}
            const response = await taxesAPI.update(tax.taxId,data);
            await this.fetchTaxes();
            
          } catch(error){
            console.error("Error while editing taxes",error)
          }
        },
        buttonClick(){
          if(this.isEdit){
            this.editDetails()
          }
          else{
            this.addDetails()
          }
        }

    },
    created() {
      this.fetchTaxes();
    },
}
</script>