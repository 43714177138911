<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>FAQ List</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">

        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
            Add
          </button>
          <!-- FAQ Table using DataTables -->
          <table ref="faqDataTable" class="table mt-3">
            <thead>
              <tr>
                <th>FAQ ID</th>
                <th>FAQ Question</th>
                <th>FAQ Answer</th>
                <th> active </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="faq  in faqs" :key="faq.faqId">
                <td>{{ faq.faqId }}</td>
                <td>{{ faq.faqQuestion }}</td>
                <td>{{ faq.faqAnswer }}</td>
                <td>
                  <p v-if="faq.active" class="ongoing">{{ faq.active }}</p>
                  <p v-else class="progress1">False</p>
                </td>
                <td>
                  <div class="three-images d-flex">
                    <a href="#" @click="openEditModal(faq)">
                      <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                    </a>
                    
                    <a href="#" @click="deleteFAQ(faq.faqId)">
                      <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-red" alt="">
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> 
           
        </div>
      </div>
    </div>
    <div id="editDetailModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ this.editingFAQ == null ? 'Add' : 'Edit' }} Details</h5>
            <button type="button" class="btn btn-danger" @click="closeEditModal">
              <span>&times;</span>
            </button>
          </div> 
          <div class="modal-body">
            <label>Question</label>
            <input type="text" v-model="question" class="form-control" placeholder="Type a question">
            <label>Answer</label>
            <input type="text" v-model="answer" class="form-control"  placeholder="Type the answer">
          </div> 
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
            <button type="button" class="btn btn-primary" @click="updateDetails">{{ this.editingFAQ == null ? 'Add' : 'Update' }}</button>
          </div>
        </div>
      </div>
    </div>     
</template>

<script>

import faqAPI from '@/api/other-configs/faq.js'
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            faqs : [],
            editingFAQ: {},
            question: '',
            answer : ''

        }
    },
    methods: {
        async fetchFaqs(){
            try{
              this.$store.commit('home/START_LOADING')
                const response =  await faqAPI.getfaq();
                this.faqs = response.data 
                this.initializeDataTable();
                this.$store.commit('home/STOP_LOADING')
            } catch (error) {
                console.error("Error fetching faqs:",error);
                this.$store.commit('home/STOP_LOADING')
            }
        },
        initializeDataTable() {
            // Destroy existing DataTable
            if ($.fn.DataTable.isDataTable(this.$refs.faqDataTable)) {
            $(this.$refs.faqDataTable).DataTable().destroy();
            }
            const columnsExport =[0,1,2,3];
            // Initialize DataTable when the component is mounted or when states change
            this.$nextTick(() => {
                $(this.$refs.faqDataTable).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                    {
                        extend: 'copy',
                        title: 'faqList',
                        exportOptions: {
                        columns: columnsExport
                        },
                    },
                    {
                        extend: 'csv',
                        title: 'faqList',
                        exportOptions: {
                        columns: columnsExport
                        },
                    },
                    {
                        extend: 'excel',
                        title: 'faqList',
                        exportOptions: {
                        columns: columnsExport
                        },
                    },
                    {
                        extend: 'pdf',
                        title: 'faqList',
                        exportOptions: {
                        columns: columnsExport
                        },
                    },
                    {
                        extend: 'print',
                        title: 'faqList',
                        exportOptions: {
                        columns: columnsExport
                        },
                    },
                    ],
                    // Add any DataTables options you need here
                });
            });
        },
        openEditModal(faq){
          this.editingFAQ = faq;
          if (faq) {
            this.question = faq.faqQuestion;
            this.answer = faq.faqAnswer;
          } else {
            this.question = '';
            this.answer = '';
          }
          const modal = document.getElementById('editDetailModal');
          if(modal){
            modal.classList.add('show');
            modal.style.display = 'block'
          }
        },
        closeEditModal(){
          this.editingFAQ = {};
          const modal = document.getElementById('editDetailModal');
            if (modal) {
              modal.classList.remove('show');
              modal.classList.remove('hide');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
              const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
              if (modalBackdrop) {
                  modalBackdrop.parentNode.removeChild(modalBackdrop);
              }
          }
        },
        async deleteFAQ(id){
          try{
            this.$store.commit('home/START_LOADING')
            const response = await faqAPI.deletefaq(id); 
            this.$store.commit('home/STOP_LOADING')           
            await this.fetchFaqs();
          } catch (error){
            console.error("Error deleting faq:",error);
            this.$store.commit('home/STOP_LOADING')
          }
        },
        async updateDetails(){
          const data = {
            faqId : this.editingFAQ !== null ? this.editingFAQ.faqId : 0,
            faqQuestion: this.question,
            faqAnswer: this.answer,
            active : true
          }
          this.$store.commit('home/START_LOADING')
          const response =  await faqAPI.updatefaq(data);
          alert(response.data.message);
          this.$store.commit('home/STOP_LOADING')
          if (response.data.success) {
            this.closeEditModal();
            await this.fetchFaqs();
          }
        }

    },
    created() {
      this.fetchFaqs();
    },
}
</script>