<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card p-4">
          <h1 class="text-center mb-4">Login</h1>
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input
                type="text"
                v-model="username"
                class="form-control"
                id="username"
                placeholder="Username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                v-model="password"
                class="form-control"
                id="password"
                placeholder="Password"
              />
            </div>
            <button type="submit" class="btn btn-primary d-block w-100">Login</button>
          </form>
          <p v-if="loginError" class="mt-3 text-danger text-center">{{ loginError }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      validationError: '',
      loginError: '',
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    async login() {
      this.validationError = '';
      if (!this.username || !this.password) {
        this.validationError = 'Username and password are required.';
        return;
      }
      try {
        this.$store.commit('home/START_LOADING')
        await this.loginUser({ username: this.username, password: this.password });
        this.$router.push({ name: 'Home' });
      } catch (error) {
        this.loginError = error.message || 'An error occurred';
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    clearInputs() {
      this.username = '';
      this.password = '';
    },
  },
};
</script>

<style>
body {
  background-color: #ffffff;
}
.card {
  background-color: white;
}
</style>
