<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Roles To Screen Mapping</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="roleDropdown" style="margin-top:5px;">Role :</label>
        <select id="roleDropdown" v-model="selectedRole" class="form-select" @change="fetchScreenMapping">
          <option disabled selected value="">SELECT ROLE</option>
          <option v-for="role in roles" :key="role.masterRoleId" :value="role.masterRoleId">{{ role.roleName }}</option>
        </select>
      </div>
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <table ref="rolesTable" class="table mt-3">
          <thead>
          <tr>
            <th>Role Id</th>
            <th>Role Name</th>
            <th>Mapped</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="screenMapping in screenMappings" :key="screenMapping.childId">
            <td>{{ screenMapping.childId }}</td>
            <td>{{ screenMapping.childName }}</td>
            <td>
              <input v-if="screenMapping.mapped" @change="update(screenMapping.childId)"  data-toggle = "toggle" id="flexSwitchCheckChecked" checked data-onstyle = "success"
                     role="switch" type="checkbox">
              <input v-else id="flexSwitchCheckChecked" @change="update(screenMapping.childId)" class="form-check-input" role="switch" type="checkbox">
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import rolesAPI from '@/api/roles/roles';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      roles: [],
      screenMappings: [],
      selectedRole: 0,
    };
  },
  methods: {
    async fetchRoles() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await rolesAPI.getRoles();
        this.roles = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching roles:', error);
      } finally {
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchScreenMapping() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await rolesAPI.getRoleToScreen(this.selectedRole);
        this.screenMappings = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching roles:', error);
      } finally {
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async update(menuId) {
      try{
        this.$store.commit('home/START_LOADING')
        const  response = await rolesAPI.getMapRolesToScreen(this.selectedRole, menuId);
        if (response.data.success) {
          alert(response.data.message);
          await this.fetchScreenMapping();
        }

      } catch (error) {
        console.error('Error while mapping roles:', error);
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    getExportTitle() {
      return 'Roles';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.rolesTable)) {
        $(this.$refs.rolesTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.rolesTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchRoles();
  },
};
</script>
