<template>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mb-2 mb-lg-0 dash-ul" style="position:relative;">
      <li class="nav-item dropdown" v-for="(menuItem, index) in menuItems" :key="index">
        <a v-if="menuItem.child && menuItem.child.length > 0 && (!menuItem.path || menuItem.path.trim() === '') !== ''"
           class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="me-1 ms-2 ms-lg-0">
          <img :src="menuItem.icon" alt="Menu Icon" class="svg-image"/></span>{{ menuItem.name }}
        </a>
        <router-link v-else class="nav-link" :to="'.'+menuItem.path">
          <span class="me-1 ms-2 ms-lg-0">
            <img :src="menuItem.icon" alt="Menu Icon" class="svg-image"/>
          </span>
          {{ menuItem.name }}
        </router-link>
        <ul class="dropdown-menu dr-menu" style="width:auto">
          <li v-for="(subMenuItem, subIndex) in menuItem.child" :key="subIndex">
            <router-link class="dropdown-item" :to="'.' + subMenuItem.path">
              <span class="me-1 ms-2 ms-lg-0">
                <img :src="subMenuItem.icon" alt="Menu Icon" class="svg-image"/>
              </span>{{ subMenuItem.name }}
            </router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" @click="logout">
        <a class="nav-link" href="#"><span class="me-1 ms-2 ms-lg-0"><img
            src="https://admin.pleatsanddrapes.com/admin_menu_icons/ic_logout.svg" alt="Menu Icon"
            class="svg-image"/></span>Logout</a>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import homeAPI from '@/api/home';

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('home', ['menuItems']),
  },
  created() {
    this.$store.commit('home/START_LOADING')
    homeAPI.getMenuItems()
        .then(response => {
          this.setMenuItems(response.data);
          this.$store.commit('home/STOP_LOADING')
        })
        .catch(error => {
          console.error('Error fetching menu items:', error);
          this.$store.commit('home/STOP_LOADING')
        }
      )

  },
  methods: {
    ...mapActions('home', ['setMenuItems']),
    ...mapActions('auth', ['logoutUser']),
    handleMenuClick(url) {
    },
    navigateToTestPage() {
      this.$router.push('/test'); 
    },
    logout() {
      this.logoutUser()
      this.$router.push('/')
    }
  },
};
</script>
