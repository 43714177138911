
import { vModelRadio } from 'vue';
<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Customer Details</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">

        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <!-- FAQ Table using DataTables -->
          <table ref="faqDataTable" class="table mt-3">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>WhatsApp Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ details.id }}</td>
                <td>{{ details.email }}</td>
                <td>{{ details.whatsapp }}</td>
                <td>
                  <div class="three-images d-flex">
                      <a href="#" @click="openEditModal()">
                      <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1">
                      </a>
                      <!--
                      <a href="#" @click="deleteState(state.stateId)">
                      <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-img">
                      </a> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table> 
          <div id="editDetailModal1" class="modal fade">
            <!-- Header-->
            <div class="modal-header">
              <h5 class="modal-title">
                Edit Details
              </h5>
              <button type="button" class="close" @click="closeEditModal">
                <span>&times;</span>
              </button>
            </div>
            <!-- Body -->
            <div class="modal-body">
              <label>WhatsApp Number</label>
              <input type="text" v-model="whatsappNumber" class="form-control" placeholder="+91 111111111">
              <label>Email ID</label>
              <input type="text" v-model="email" class="form-control" placeholder="sample@gmail.com">
            </div>
            <!-- Footer-->
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
              <button type="button" class="btn btn-primary" @click="updateDetails">Save Changes</button>
            </div>  
          </div> 
          
        </div>
      </div>
    </div>
    <div id="editDetailModal" class="modal fade">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Edit Header -->
                <div class="modal-header">
                  <h5 class="modal-title">Edit Details</h5>
                  <button type="button" class="close" @click="closeEditModal">
                    <span>&times;</span>
                  </button>
                </div>
                <!-- Edit Body -->
                <div class="modal-body">
                  <label>WhatsApp Number</label>
                  <input type="text" v-model="whatsappNumber" class="form-control" placeholder="+91 111111111">
                  <label>Email ID</label>
                  <input type="text" v-model="email" class="form-control" placeholder="sample@gmail.com">
                </div>
                <!-- Edit Footer -->
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                  <button type="button" class="btn btn-primary" @click="updateDetails">Update</button>
                </div>
              </div>
            </div>
          </div> 
</template>

<script>

import customerSupportAPI from '@/api/other-configs/customerSupport.js'
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            details : [],
            editFlag: true,
            whatsappNumber : '',
            email : '',
        }
    },
    methods: {
        async fetch(){
            try{
              this.$store.commit('home/START_LOADING')
                const response = await customerSupportAPI.getDetails();
                this.details = response.data
              this.initializeDataTable();
              this.$store.commit('home/STOP_LOADING')
            } catch (error) {
                console.error("Error fetching countries:",error);
                this.$store.commit('home/STOP_LOADING')
            }
        },
        initializeDataTable() {
            // Destroy existing DataTable
            if ($.fn.DataTable.isDataTable(this.$refs.faqDataTable)) {
            $(this.$refs.faqDataTable).DataTable().destroy();
            }
            // Initialize DataTable when the component is mounted or when states change
            this.$nextTick(() => {
                $(this.$refs.faqDataTable).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                    {
                        extend: 'copy',
                        title: 'faqList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'csv',
                        title: 'faqList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'excel',
                        title: 'faqList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'pdf',
                        title: 'faqList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'print',
                        title: 'faqList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    ],
                    // Add any DataTables options you need here
                });
            });
        },
        openEditModal(){
          this.whatsappNumber=this.details.whatsapp
          this.email=this.details.email
          const modal = document.getElementById('editDetailModal');
          if(modal){
            modal.classList.add('show');
            modal.style.display = 'block'
          }
        },
        closeEditModal(){
          // make null values
          this.whatsappNumber=''
          this.email=''
          const modal = document.getElementById('editDetailModal');
            if (modal) {
              modal.classList.remove('show');
              modal.classList.remove('hide');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
              const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
              if (modalBackdrop) {
                  modalBackdrop.parentNode.removeChild(modalBackdrop);
              }
          }
        },
        async updateDetails(){
          const data={whatsappNumber:this.whatsappNumber,emailId:this.email};
          this.$store.commit('home/START_LOADING')
          const response = await customerSupportAPI.updateDetails(data);
          this.closeEditModal()
          this.$store.commit('home/STOP_LOADING')
          this.fetch()
        }

    },
    created() {
      this.fetch();
    },
}
</script>