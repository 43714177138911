  <template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Store Wise Services Configuration</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">
          <!-- Country Dropdown -->
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
            <label for="countryDropdown" style="margin-top:5px;">Country:</label>
            <select v-model="selectedCountry" @change="fetchStates(selectedCountry)" id="countryDropdown" class="form-select"
                    :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') ">
              <option value="0">Select Country</option>
              <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                {{ country.countryName }}
              </option>
            </select>
          </div>

          <!-- State Dropdown -->
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
            <label for="stateDropdown" style="margin-top:5px;">State:</label>
            <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState,)" id="stateDropdown" class="form-select"
                    :disabled="(configData.masterRolesId === '3' || configData.masterRolesId === '4') ">
              <option value="0">Select State</option>
              <option v-for="state in states" :key="state.stateId" :value="state.stateId">{{ state.stateName }}</option>
            </select>
          </div>

          <!-- city Dropdown -->
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
            <label for="cityDropdown" style="margin-top:5px;">City:</label>
            <select :disabled="(configData.masterRolesId === '3') "  v-model="selectedCity" @change="fetchStores(selectedCountry,selectedState,selectedCity)" id="cityDropdown" class="form-select">
              <option value="0">Select City</option>
              <option v-for="city in cities" :key="city.cityId" :value="city.cityId">{{ city.cityName }}</option>
            </select>
          </div>

          <!-- Store Dropdown -->
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
            <label for="storeDropdown" style="margin-top:5px;">Store:</label>
            <select v-model="selectedStore" id="storeDropdown" class="form-select">
              <option value="0">Select Store</option>
              <option v-for="store in stores" :key="store.storeId" :value="store.storeId">{{ store.storeName }}</option>
            </select>
          </div>

          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5" style="margin-top:25px;">
            <button @click="fetchStoreMappings" class="btn btn-success">Submit</button>
          </div>

          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table ref="storesTable" class="table mt-3" >
                  <thead>
                  <tr>
                    <th>Service Id</th>
                    <th>Service Name</th>
                    <th>Mapped</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="storeMapping in storeMappings" :key="storeMapping.childId">
                    <td>{{ storeMapping.childId }}</td>
                    <td>{{ storeMapping.childName }}</td>
                    <td>
                      <input v-if="storeMapping.mapped " @change="update(storeMapping.childId)"  data-toggle = "toggle" id="flexSwitchCheckChecked" checked data-onstyle = "success"
                             role="switch" type="checkbox">
                      <input v-else id="flexSwitchCheckChecked" @change="update(storeMapping.childId)" class="form-check-input" role="switch" type="checkbox">
                    </td>
                  </tr>
                  </tbody>
                </table>
          </div>
      </div>
    </div>
  </template>

  <script>
  import stateAPI from '@/api/location/state';
  import countryAPI from '@/api/location/country';
  import cityAPI from '@/api/location/cities.js';
  import storeAPI from '@/api/store/store.js';
  import $ from 'jquery';
  import 'datatables.net-bs5';
  import 'datatables.net-buttons-bs5';
  import 'datatables.net-buttons/js/buttons.html5';
  import 'datatables.net-buttons/js/buttons.print';
  import 'jszip/dist/jszip';
  import 'pdfmake/build/pdfmake';
  import 'pdfmake/build/vfs_fonts';
  import rolesAPI from "@/api/roles/roles";

  export default {
    data() {
      return {
        selectedCountry: 0,
        selectedState: 0,
        selectedCity: 0,
        selectedOwner: 0,
        selectedStore: 0,
        countries: [],
        states: [],
        storeMappings: [],
        cities: [],
        stores: [],
        configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
      /*  owners: [],*/
      };
    },
    methods: {
      async fetchCountries() {
        try {
          this.$store.commit('home/START_LOADING')
          const response = await countryAPI.getCountries();
          this.countries = response.data;
        } catch (error) {
          console.error('Error fetching countries:', error);
        } finally{
          this.$store.commit('home/STOP_LOADING')
        }
      },
      async fetchStates(country) {
        try {
          this.$store.commit('home/START_LOADING')
          const response = await stateAPI.getStates(country);
          this.states = response.data;
        } catch (error) {
          console.error('Error fetching states:', error);
        } finally{
          this.$store.commit('home/STOP_LOADING')
        }
      },
      async fetchCities(country,state) {
        try {
          this.$store.commit('home/START_LOADING')
          const response = await cityAPI.getCities(country,state);
          this.cities = response.data;
          await this.fetchStores(country,state,this.selectedCity ? this.selectedCity : null);
        } catch (error) {
          console.error('Error fetching cities:', error);
        } finally{
          this.$store.commit('home/STOP_LOADING')
        }
      },
      async update(serviceId) {
        try{
          this.$store.commit('home/START_LOADING')
          const  response = await storeAPI.mapStoreToService(this.selectedStore, serviceId);
          if (response.data.success) {
            alert(response.data.message);
            await this.fetchStoreMappings();
          }

        } catch (error) {
          console.error('Error while mapping roles:', error);
          alert(error.response.data.message);
        } finally{
          this.$store.commit('home/STOP_LOADING')
        }
      },
      async fetchStores(country,state,city) {
        try {
          this.$store.commit('home/START_LOADING')
          const table = this.$refs.storesTable;
          let path = "";
          if (country !== null && country !== 0) {
            path += `country_id=${country}`;
          }
          if (state !== null && state !== 0) {
            path += `&state_id=${state}`;
          }
          if (city !== null && city !== 0) {
            path += `&city_id=${city}`;
          }
          if(country==='undefined')
          {
            return false;
          }
          const response = await storeAPI.getStores(path, true);
          this.stores = response.data;
          if (table.style.display === 'none') {
            table.style.display = 'block';
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        } finally{
          this.$store.commit('home/STOP_LOADING')
        }
      },
      async fetchStoreMappings() {
        if (this.selectedStore == null || this.selectedStore === 0) {
          alert("please select store");
        } else {
          try {
            this.$store.commit('home/START_LOADING')
            const response = await storeAPI.fetchStoreMappings(this.selectedStore);
            this.storeMappings = response.data;
            this.initializeDataTable();
          } catch (error) {
            console.error('Error fetching store Mappings:', error);
          } finally{
            this.$store.commit('home/STOP_LOADING')
          }
        }
      },
      getExportTitle() {
        const selectedCountry = this.countries.find(country => country.countryId === this.selectedCountry);
        if (selectedCountry) {
          return `${selectedCountry.countryName.replace(/ /g, '_')}_states`;
        } else {
          return 'Exported_states';
        }
      },
      initializeDataTable() {
        if ($.fn.DataTable.isDataTable(this.$refs.storesTable)) {
          $(this.$refs.storesTable).DataTable().destroy();
        }
        let columnsToExport = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        this.$nextTick(() => {
          $(this.$refs.storesTable).DataTable({
            dom: 'Bfrtip',
            buttons: [
              {
                extend: 'copy',
                title: this.getExportTitle(),
                exportOptions: {
                  columns: columnsToExport
                },
              },
              {
                extend: 'csv',
                title: this.getExportTitle(),
                exportOptions: {
                  columns: columnsToExport
                },
              },
              {
                extend: 'excel',
                title: this.getExportTitle(),
                exportOptions: {
                  columns: columnsToExport
                },
              },
              {
                extend: 'pdf',
                title: this.getExportTitle(),
                exportOptions: {
                  columns: columnsToExport
                },
              },
              {
                extend: 'print',
                title: this.getExportTitle(),
                exportOptions: {
                  columns: columnsToExport
                },
              },
            ],
            searchHighlight: true,
          });
        });
      },
    },
    created() {
      if (this.configData.masterRolesId === '2') {
        this.fetchCountries();
        this.selectedCountry = this.configData.countryId;
        this.fetchStates(this.configData.countryId);
        this.selectedState = this.configData.stateId;
        this.fetchCities(this.configData.countryId, this.configData.stateId);
      } else if (this.configData.masterRolesId === '4') {
        this.fetchCountries();
        this.selectedCountry = this.configData.countryId;
        this.fetchStates(this.configData.countryId);
        this.selectedState = this.configData.stateId;
        this.fetchCities(this.configData.countryId, this.configData.stateId);
      } else if (this.configData.masterRolesId === '3') {
        this.fetchCountries();
        this.selectedCountry = this.configData.countryId;
        this.fetchStates(this.configData.countryId);
        this.selectedState = this.configData.stateId;
        this.fetchCities(this.configData.countryId, this.configData.stateId);
        this.selectedCity = this.configData.cityId;
      } else {
        this.fetchCountries();
        this.selectedCountry = '1';
        this.fetchStates('1');
      }
    },
  };
  </script>

