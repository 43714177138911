<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>OTP Page</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailFromAddress" style="margin-top:5px;"> Email from Address :</label>
        <input id="emailFromAddress" v-model="data.emailFromAddress" class="form-control" type="text">
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailHost" style="margin-top:5px;">Email Host:</label>
        <input id="emailHost" v-model="data.emailHost" class="form-control" type="text">
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailpassword" style="margin-top:5px;"> Email Password :</label>
        <input id="emailPassword" v-model="data.emailPassword" class="form-control" type="text">

      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailSubject" style="margin-top:5px;">Email Subject:</label>
        <input id="emailSubject" v-model="data.emailSubject" class="form-control" type="text">
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailFromAddress" style="margin-top:5px;"> Email Port :</label>
        <input id="emailPort" v-model="data.emailPort" class="form-control" type="text">
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailHost" style="margin-top:5px;">Message Content:</label>
        <textarea id="messageContent" v-model="data.messageContent" class="form-control"/>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailHost" style="margin-top:5px;">Reset Message Content :</label>
        <textarea id="resetMessageContent" v-model="data.resetMessageContent" class="form-control"/>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailHost" style="margin-top:5px;">SMS Service Data :</label>
        <textarea id="smsServiceData" v-model="data.smsServiceData" class="form-control"/>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="emailHost" style="margin-top:5px;">SMS Service Slug:</label>
        <textarea id="smsServiceSlug" v-model="data.smsServiceSlug" class="form-control"/>
      </div>
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <button class="btn btn-success mt-2" @click="update" type="submit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import otpAPI from '@/api/other-configs/otp';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      data: {}
    };
  },
  methods: {
    async fetch() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await otpAPI.get();
        this.data = response.data;
        this.$store.commit('home/STOP_LOADING')
      } catch (error) {
        console.error('Error fetching admins:', error);
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async update() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await otpAPI.update(this.data);
        this.$store.commit('home/STOP_LOADING')
        if (response.data.success) {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error while updating", error);
        this.$store.commit('home/STOP_LOADING')
      }
    }
  },
  created() {
    this.fetch();
  },
};
</script>
  