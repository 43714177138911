<!-- App.vue --> 
<template> 
  <section id="dash-board">
    <div class="container-fluid px-0">
      <div class="row m-0 p-0">
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-0">
        <div class="pleats d-flex ms-4 mt-2">
          <img src="@/assets/images/ic_logo.png" class="img-fluid">
          <p>Pleats & Drapes</p>
        </div>
          <div>
            <p class="pleats d-flex ms-3" v-if="$route.path !== '/'" > {{getName() }}</p>
          </div>
 
          <nav class="navbar navbar-expand-lg mt-0 mt-lg-4">
            <div class="container-fluid px-0">
              <div class="text-end">
                <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <template v-if="$route.path !== '/'">
            <menuBar class="menu" /> 
            </template>
          </nav>
        </div>  
          <router-view></router-view>
          <div v-if="store.state.home.loadingCount != 0" class="spinner-container">
            <div class="spinner"></div>
          </div>
      </div>  
    </div>    
  </section>
</template>

<script setup>
import menuBar from './components/SideBar.vue';
import bootstrapBundle from '@/assets/js/bootstrap.bundle.min.js';
import store from '@/store';
function getName(){
  const configData=localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null;
  if(configData?.fullName){
    return 'Welcome '+configData.fullName;
  }
  else{
    return '';
  }
}
</script>

<style>
@import '@/assets/css/style.css';
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/dataTables.bootstrap5.min.css';
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Optional: Add a fade animation for smooth entrance/exit */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
