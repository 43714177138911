<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Notification List</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">

        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <button @click="openAddModal" class="btn btn-success" style="position:absolute;right:30px;">
            Add
          </button>
          <!-- notification Table using DataTables -->
          <table ref="notificationDataTable" class="table mt-3">
            <thead>
              <tr>
                <th>Notification ID</th>
                <th>Title</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="notification  in notifications" :key="notification.notificationId">
                <td>{{ notification.notificationId }}</td>
                <td>{{ notification.title }}</td>
                <td>{{ notification.message }}</td>
                
              </tr>
            </tbody>
          </table> 
           
        </div>
      </div>
    </div>  
    <div id="addModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Details</h5>
            <button type="button" class="btn btn-danger" @click="closeEditModal">
              <span>&times;</span>
            </button>
          </div> 
          <div class="modal-body">
            <label>Title</label>
            <input type="text" v-model="title" class="form-control" placeholder="title">
            <label>Message</label>
            <input type="text" v-model="message" class="form-control"  placeholder="message">
          </div> 
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
            <button type="button" class="btn btn-primary" @click="addDetails">Push</button>
          </div>
        </div>
      </div>
    </div>      
</template>

<script>

import notificationAPI from '@/api/other-configs/pushNotifications.js'
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            notifications : [],
            title:'',
            message:''
        }
    },
    methods: {
        async fetchNotifications(){
            try{
              this.$store.commit('home/START_LOADING')
                const response =  await notificationAPI.getnotifications();
                this.notifications = response.data 
                this.initializeDataTable();
            } catch (error) {
                console.error("Error fetching countries:",error);
            } finally{
              this.$store.commit('home/STOP_LOADING')
            }
        },
        initializeDataTable() {
            // Destroy existing DataTable
            if ($.fn.DataTable.isDataTable(this.$refs.notificationDataTable)) {
            $(this.$refs.notificationDataTable).DataTable().destroy();
            }
            // Initialize DataTable when the component is mounted or when states change
            this.$nextTick(() => {
                $(this.$refs.notificationDataTable).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                    {
                        extend: 'copy',
                        title: 'notificationList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'csv',
                        title: 'notificationList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'excel',
                        title: 'notificationList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'pdf',
                        title: 'notificationList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'print',
                        title: 'notificationList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    ],
                    // Add any DataTables options you need here
                });
            });
        },
        openAddModal(){
          this.title=''
          this.message=''
          const modal = document.getElementById('addModal');
          if(modal){
            modal.classList.add('show');
            modal.style.display = 'block';
          }
        },
        closeEditModal(){
          // make null values
          this.title=''
          this.message=''
          const modal = document.getElementById('addModal');
            if (modal) {
              modal.classList.remove('show');
              modal.classList.remove('hide');
              modal.style.display = 'none';
              document.body.classList.remove('modal-open');
              const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
              if (modalBackdrop) {
                  modalBackdrop.parentNode.removeChild(modalBackdrop);
              }
          }
        },
        async addDetails(){
          try{
            this.$store.commit('home/START_LOADING')
            const data = {message:this.message,title:this.title}
            const response =  await notificationAPI.postNotification(data);
            this.closeEditModal()
            this.fetchNotifications();
          }catch(error){
            console.error("Error while adding notifications",error)
          } finally{
            this.$store.commit('home/STOP_LOADING')
          }
        }

    },
    created() {
      this.fetchNotifications();
    },
}
</script>