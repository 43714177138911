<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Service Category Items</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="categoryDropdown" style="margin-top:5px;">Select Category:</label>
        <select v-model="selectedCategory" id="categoryDropdown" class="form-select">
          <option value="0">ALL</option>
          <option v-for="category in categories" :key="category.categoryId" :value="category.categoryId">
            {{ category.categoryName }}
          </option>
        </select>
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="serviceDropdown" style="margin-top:5px;">Select Service:</label>
        <select v-model="selectedService" id="serviceDropdown" class="form-select">
          <option value="0">ALL</option>
          <option v-for="service in services" :key="service.serviceId" :value="service.serviceId">{{
              service.serviceName
            }}
          </option>
        </select>
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5" style="margin-top:25px;">
        <button @click="fetchServiceCategoryItems" class="btn btn-success">Submit</button>
      </div>


      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="categoryItemsTable" class="table mt-3" style="display: none">
          <thead>
          <tr>
            <th>Item Id</th>
            <th>Item Name</th>
            <th>Item Image</th>
            <th>Price</th>
            <th>CategoryName</th>
            <th>Note</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="categoryItem in categoryItems">
            <td>{{ categoryItem.serviceCategoryItemId }}</td>
            <td>{{ categoryItem.itemName }}</td>
            <td><img :src="categoryItem.itemImage" alt="" width="100" height="70"></td>
            <td>{{ categoryItem.price }}</td>
            <td>{{ categoryItem.categoryName }}</td>
            <td>{{ categoryItem.note }}</td>
            <td>
              <p v-if="categoryItem.active" class="ongoing" style="text-align:center">{{ categoryItem.active }}</p>
              <p v-else class="progress1" style="text-align:center">{{ categoryItem.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(categoryItem,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Category Items</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Select Category</label>
                  <div class="col-sm-8">
                    <select v-model="updatedCategory" id="categoryDropdown" class="form-select">
                      <option value="0">select Category</option>
                      <option v-for="category in categories"
                              :key="category.categoryId" :value="category.categoryId" :disabled="!category.active">
                        {{ category.categoryName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Select Service </label>
                  <div class="col-sm-8">
                    <select v-model="updatedService" id="serviceDropdown" class="form-select">
                      <option value="0">Select Service</option>
                      <option v-for="service in services" :key="service.serviceId" :value="service.serviceId"
                              :disabled="!service.active">
                        {{ service.serviceName }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Item Name </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedItemName" class="form-control" placeholder="Item Name">
                  </div>
                </div>

                <div v-if="editFlag" class="row mb-1">
                  <label class="col-sm-2 col-form-label">Item Image Name </label>
                  <div class="col-sm-2">
                    <img :src="updatedItemImage" alt="" width="70" height="70">
                  </div>
                  <div class="col-sm-8">
                    <input type="file" @change="handleFileChange" class="form-control">
                  </div>
                </div>
                <div v-else class="row mb-1">
                  <label class="col-sm-4 col-form-label">Item Image Name </label>
                  <div class="col-sm-8">
                    <input type="file" @change="handleFileChange" class="form-control">
                  </div>
                </div>


                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Price </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedPrice" class="form-control" placeholder="Price">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Note </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedNote" class="form-control" placeholder="Note">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="col-sm-8">
                    <select v-if="editFlag" v-model="updatedActive" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="update">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import serviceAPI from '@/api/service/service';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      categories: [],
      services: [],
      categoryItems: [],
      selectedCategory: 0,
      selectedService: 0,
      editingServiceCategoryItem: null,
      editFlag: false,
      updatedItemName: null,
      updatedItemImage: null,
      updatedPrice: null,
      updatedNote: null,
      updatedActive: null,
      updatedCategory: '',
      updatedService: '',
      file: '',
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openEditModal(serviceCategoryItem, flag) {
      this.editingServiceCategoryItem = serviceCategoryItem;
      this.editFlag = flag;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedItemName = this.editingServiceCategoryItem.itemName;
        this.updatedItemImage = this.editingServiceCategoryItem.itemImage;
        this.updatedPrice = this.editingServiceCategoryItem.price;
        this.updatedNote = this.editingServiceCategoryItem.note;
        this.updatedActive = this.editingServiceCategoryItem.active;
        this.updatedCategory = this.editingServiceCategoryItem.categoryId;
        this.updatedService = this.editingServiceCategoryItem.serviceId;
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      } else {
        this.updatedCategoryName = "";
        this.updatedActive = true;
        this.updatedCategory = this.selectedService;
        this.updatedService = this.selectedCategory;
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      }
    },
    closeEditModal() {
      this.updatedCategoryName = "";
      this.updatedActive = "";
      this.updatedCategory = '';
      this.updatedService = '';
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchService() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await serviceAPI.getServices();
        this.services = response.data;
      } catch (error) {
        console.error(error);
        alert(error.response.data.message);
      } finally { 
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchServiceCategory() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await serviceAPI.getServiceCategory();
        this.categories = response.data;
        // this.categories = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error(error, 'Error fetching service category ');
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async fetchServiceCategoryItems() {
      const table = this.$refs.categoryItemsTable;


      if (table.style.display === 'none') {
        table.style.display = 'block';
      }
      let path = "";
      if (this.selectedService !== null) {
        path += `service_id=${this.selectedService}`;
      }
      if (this.selectedCategory !== null && this.selectedCategory !== 0) {
        path += `&category_id=${this.selectedCategory}`;
      }
      try {
        this.$store.commit('home/START_LOADING')
        const response = await serviceAPI.getServiceCategoryItem(path);
        this.categoryItems = response.data;

        this.initializeDataTable();
      } catch (error) {
        console.error(error, 'Error fetching service category items ');
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async update() {
      let formData = new FormData();
      formData.append('itemName', this.updatedItemName);
      formData.append('price', this.updatedPrice);
      if (this.updatedNote !== null && this.updatedNote !== undefined) {
        formData.append('note', this.updatedNote);
      } else {
        formData.append('note', '');
      }
      if (this.file) {
        if (this.file.size > 100 * 1024 * 1024) {
          alert('File size should be less than 100MB');
        }
        if (!['image/jpeg', 'image/png', 'application/pdf'].includes(this.file.type)) {
          alert('File type not supported. Supported types: JPEG, PNG, PDF');
        }
        formData.append('itemImage', this.file);
      }
      formData.append('active', this.updatedActive);
      try {
        let response = null;
        this.$store.commit('home/START_LOADING')
        if (this.editFlag) {
          formData.append('serviceId', this.updatedService);
          formData.append('categoryId', this.updatedCategory);
          response = await serviceAPI.updateServiceCategoryItem(this.editingServiceCategoryItem.serviceCategoryItemId, formData);
        } else {
          formData.append('serviceId', this.updatedService);
          formData.append('categoryId', this.updatedCategory);
          response = await serviceAPI.addServiceCategoryItem(formData);
        }
        if (response.data.success) {
          alert(response.data.message);
          await this.fetchServiceCategoryItems();
          this.closeEditModal();
        }
      } catch (error) {
        console.error(error, 'Error while Updating category items :');
        alert(error.response.data.message);
      } finally{
        this.$store.commit('home/STOP_LOADING')
      }
    },
    getExportTitle() {
      return 'Services';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.categoryItemsTable)) {
        $(this.$refs.categoryItemsTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.categoryItemsTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchService();
    this.fetchServiceCategory();
  },
};
</script>
