<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>State List</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <!-- Country Dropdown -->
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="countryDropdown" style="margin-top:5px;">Select Country:</label>
        <select v-model="selectedCountry" @change="onCountryChange" id="countryDropdown" class="form-select">
          <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
            {{ country.countryName }}
          </option>
        </select>
      </div>

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button v-if="selectedCountry" @click="openAddModal" class="btn btn-success"
                style="position:absolute;right:30px;">
          Add
        </button>
        <!-- State Table using DataTables -->
        <table ref="stateDataTable" class="table mt-3">
          <thead>
          <tr>
            <th>State ID</th>
            <th>State Name</th>
            <th>Country Name</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="state in states" :key="state.stateId">
            <td>{{ state.stateId }}</td>
            <td>{{ state.stateName }}</td>
            <td>{{ state.countryName }}</td>
            <td>
              <p v-if="state.active" class="ongoing" style="text-align:center">{{ state.active }}</p>
              <p v-else class="progress1" style="text-align:center">{{ state.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(state)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1">
                </a>
                <!--
                <a href="#" @click="deleteState(state.stateId)">
                  <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-img">
                </a> -->
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <!-- Edit Modal -->
        <div id="editStateModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Edit State</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Country </label>
                  <div class="col-sm-8">
                    <select v-model="updatedCountry" @change="onCountryChange" id="countryDropdown" class="form-select">
                      <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> State </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedStateName" class="form-control" placeholder="State Name">
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-4 col-form-label"> Active </label>
                  <div class="col-sm-8">
                    <select v-model="stateActive" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateState">Update</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Modal -->
        <div id="addStateModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add State</h5>
                <button type="button" class="btn btn-danger" @click="closeAddModal">
                  <span>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Country </label>
                  <div class="col-sm-8">
                    <select v-model="updatedCountry" @change="onCountryChange" id="countryDropdown" class="form-select">
                      <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> State </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="newStateName" class="form-control" placeholder="State Name">
                  </div>
                </div>
              </div>
              <!-- Add States Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeAddModal">Close</button>
                <button type="button" class="btn btn-primary" @click="addState">Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!--
          <form @submit.prevent="addState" class="mt-3">
            <label for="stateName">State Name:</label>
            <input v-model="newStateName" type="text" id="stateName" required class="form-control" />
            <button type="submit" class="btn btn-success mt-2">Add State</button>
          </form>
          -->
      </div>
    </div>
  </div>
</template>

<script>
import stateAPI from '@/api/location/state';
import countryAPI from '@/api/location/country';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      selectedCountry: 0,
      countries: [],
      states: [],
      newStateName: '',
      editingState: null,
      updatedStateName: '',
      updatedCountry: 0,
      stateActive: '',
    };
  },
  methods: {
    openEditModal(state) {
      this.editingState = state;
      this.updatedStateName = state.stateName;
      this.updatedCountry = this.selectedCountry;
      this.stateActive = state.active;
      const modal = document.getElementById('editStateModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.editingState = null;
      this.updatedStateName = '';
      this.stateActive = '';
      const modal = document.getElementById('editStateModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    openAddModal() {
      this.updatedCountry = 1;
      const modal = document.getElementById('addStateModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeAddModal() {
      this.newStateName = '';
      this.updatedCountry = '';
      const modal = document.getElementById('addStateModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async updateState() {
      if (this.updatedStateName.trim() !== '') {
        try {
          this.$store.commit('home/START_LOADING')
          const response = await stateAPI.updateState({
            stateName: this.updatedStateName,
            stateId: this.editingState.stateId,
            countryId: this.updatedCountry,
            active: this.stateActive,
          });
          this.$store.commit('home/STOP_LOADING')
          alert(response.data.message);
          if (response.data.success) {
            await this.onCountryChange();
            this.closeEditModal();
          }
        } catch (error) {
          console.error('Error updating state:', error);
          alert(error.response.data.message);
          this.$store.commit('home/STOP_LOADING')
        }
      } else {
        alert('State name cannot be empty');
      }
    },
    async fetchCountries() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
        this.$store.commit('home/STOP_LOADING')
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async onCountryChange() {
      try {
        this.$store.commit('home/START_LOADING')
        const response = await stateAPI.getStates(this.selectedCountry);
        this.states = response.data;
        this.initializeDataTable();
        this.$store.commit('home/STOP_LOADING')
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
        this.$store.commit('home/STOP_LOADING')
      }
    },
    async addState() {
      if (this.newStateName.trim() !== '' && this.selectedCountry !== 0) {
        try {
          this.$store.commit('home/START_LOADING')
          const response = await stateAPI.updateState({
            stateName: this.newStateName,
            stateId: 0,
            countryId: this.updatedCountry,
            active: true,
          });
          this.$store.commit('home/STOP_LOADING')
          alert(response.data.message);
          if (response.data.success) {
            this.selectedCountry = this.updatedCountry;
            await this.onCountryChange(); // Refresh states after adding
            // Clear the input field
            this.newStateName = '';
            this.closeAddModal();
          }
          
        } catch (error) {
          console.error('Error adding state:', error);
          alert(error.response.data.message);
          this.$store.commit('home/STOP_LOADING')
        }
      } else {
        alert('State name & Country cannot be empty');
      }
    },
    getExportTitle() {
      const selectedCountry = this.countries.find(country => country.countryId === this.selectedCountry);
      if (selectedCountry) {
        return `${selectedCountry.countryName.replace(/ /g, '_')}_states`;
      } else {
        return 'Exported_states';
      }
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.stateDataTable)) {
        $(this.$refs.stateDataTable).DataTable().destroy();
      }
      const exportColumns = [0,1,2,3];
      this.$nextTick(() => {
        $(this.$refs.stateDataTable).DataTable({
          mark: true,
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchCountries();
    this.selectedCountry = 1;
    this.onCountryChange();
  },
};
</script>
